/**
 * OOI-specific experiments should be listed here.
 * scope: viewer-apps-14bcded7-0066-7c35-14d7-466cb3f09103
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/guineapig/experiments/3279465
   */
  REMOVE_CATEGORIES_LIVE_SITE_EDITING:
    'specs.wixBlog.RemoveCategoriesLiveSiteEditing',
  /**
   * https://bo.wix.com/petri/experiments/3288198
   */
  FIX_MY_POSTS_USER_ID: 'specs.wixBlog.FixMyPostsUserId',
  /**
   * https://bo.wix.com/guineapig/experiments/3401428
   */
  POST_LIST_WIDGET_USE_ADAPTER: 'specs.wixBlog.PostListWidgetUseAdapter',
  /**
   * https://bo.wix.com/guineapig/experiments/3441890
   */
  CATEGORY_SEO_PLATFORMIZED:
    'specs.wixBlog.GenerateCategorySEOTagsPlatformized',
  /**
   * https://bo.wix.com/guineapig/experiments/3508196
   */
  INSTALL_SEARCH: 'specs.wixBlog.InstallSearch',
  /**
   * https://bo.wix.com/guineapig/experiments/3646388
   */
  PLATFORMIZED_CATEGORY_MENU_WIDGET:
    'specs.wixBlog.PlatformizedCategoryMenuWidget',
  /**
   * https://bo.wix.com/guineapig/experiments/3627662
   */
  USE_PLATFORM_API: 'specs.wixBlog.migratedToNewPlatformApi',
};
